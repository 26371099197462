export const convertToGetParams = (params) => {
  const queryString = []
  for (const key in params) {
    const param = params[key]
    queryString.push(
      `${encodeURIComponent(key)}=${encodeURIComponent((param || '') + '')}`
    )
  }
  return queryString.join('&')
}
