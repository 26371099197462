import PropTypes from 'prop-types'
import React, { useState, createContext, useContext } from 'react'

import styles from '../../theme/themes.module.scss'

const themeContext = createContext()

export const useTheme = () => {
  const context = useContext(themeContext)

  const [isDarkmode, setIsDarkmode] = context

  const setDarkmodeOn = () => {
    setIsDarkmode(true)
  }

  const setDarkmodeOff = () => {
    setIsDarkmode(false)
  }

  const toggle = () => {
    setIsDarkmode((prev) => !prev)
  }

  return { isDarkmode, setDarkmodeOn, setDarkmodeOff, toggle }
}

export const ThemeProvider = (props) => {
  const [isDarkmode, setIsDarkmode] = useState(false)
  const theme = isDarkmode ? styles.darkTheme : styles.defaultTheme

  return (
    <themeContext.Provider value={[isDarkmode, setIsDarkmode]}>
      <div className={theme}>{props.children}</div>
    </themeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}
