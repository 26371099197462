import Bugsnag from '@bugsnag/js'

const logError = (error, fnName, metaData = {}) => {
  window.console.log('logError: ', fnName, metaData, error)
  Bugsnag.notify(error, (event) => {
    event.addMetadata('remarks', {
      ...window?.globals?._logData,
      ...metaData,
      function: fnName,
      logger: 'logError',
    })
  })
}

export default logError
